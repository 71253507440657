import React, { FC, useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { AccountContext } from '@/features/account-context';
import { storeDomain } from '@/features/shop-settings/index';
import { BigButton } from '@/components/big-button';
import { useResubscribe } from '@/features/shop-settings/useResubscribe';
import { PageLayout } from '@/components/page-layout';

export const PleaseReinstallPage: FC = () => {
  const ctx = useContext(AccountContext);
  const domain = storeDomain(ctx);
  const status = ctx.account?.store?.subscription?.status;
  const notActive = [
    `CANCELLED`,
    `EXPIRED`,
    `PENDING`,
    `DECLINED`,
    `FROZEN`,
  ].includes(status);
  const { reSubscribe, loading } = useResubscribe();
  return (
    <PageLayout hideBackButton>
      <Wrapper>
        <Background />
        <Modal>
          <div>
            <h1>
              {domain} subscription <br />
              is invalid
            </h1>
            {notActive ? (
              <p>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                You haven't completed the subscription process, <br />
                Please confirm the subscription terms from Shopify to start
                using Visually.io.
              </p>
            ) : (
              <p>
                Unlock the full potential of the Visually.io
                <br /> by reinstalling and reactivating your subscription today!
              </p>
            )}
          </div>
          <div>
            <BigButton
              br="2rem"
              noTransform
              disabled={loading}
              fillWidth
              noShadow
              size="xl"
              onClick={async () => {
                if (notActive) {
                  if (!loading) {
                    const resp = await reSubscribe();
                    resp?.checkoutUrl &&
                      window.location.assign(resp?.checkoutUrl);
                  }
                } else {
                  // active subscription but uninstalled
                  window.location.assign(
                    `https://apps.shopify.com/visually-io`,
                  );
                }
              }}
            >
              {notActive ? `Renew Subscription` : `Install Visually.io`}
            </BigButton>
          </div>
        </Modal>
      </Wrapper>
    </PageLayout>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 12rem);
  width: 100%;

  .gatsby-image-wrapper {
    position: absolute;
    inset: 0;
  }
`;

const Modal = styled.div`
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15),
    0 4px 11px 0 rgba(151, 210, 206, 0.22);
  width: 60%;
  z-index: 1;
  background-color: white;
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    text-align: center;

    h1 {
      font-size: 2.5rem;
      line-height: 35px;
    }

    p {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 35px;
    }
  }

  div:nth-child(2) {
    display: flex;
    width: 40%;
    margin-bottom: 4rem;
  }
`;

const Background: FC = () => (
  <StaticImage
    formats={[`png`, `webp`]}
    style={{
      position: `fixed`,
      top: `7rem`,
      left: 0,
      width: `100%`,
      height: `100%`,
      zIndex: 0,
    }}
    src="disabled_app.png"
    alt="bg"
  />
);
