import { useContext } from 'react';
import { CachePolicies, useFetch } from 'use-http';
import { AccountContext } from '@/features/account-context';
import { routes } from '@/webapi/routes';

export function useResubscribe() {
  const ctx = useContext(AccountContext);
  const store = ctx?.account?.store;
  const { get, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
    },
  }));

  async function reSubscribe(): Promise<{ checkoutUrl: string } | undefined> {
    if (store?.alias) {
      return get(routes.reSubscribe(store?.alias));
    }
    return undefined;
  }

  return { reSubscribe, loading };
}
